import { FC } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { DEV_ABSOLUTE_URL, slickSliderConfig } from '@/helpers/variables';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';
import { displayNoneImgOnError } from '@/helpers/helpers';
import styles from './SlickSlider.module.scss';

interface ISlickSliderPartner {
  books: IAdvertisingBlock;
}

const SlickSliderPartner: FC<ISlickSliderPartner> = ({ books }) => {
  return (
    <div className={styles.booksSlider}>
      <div className={styles.booksSlider__wrap}>
        <Slider {...slickSliderConfig}>
          {books.map((book) => {
            const { title, litres_url, external_id } = book;

            return (
              <div key={book.id} className={styles.booksSlider__item}>
                <div className={styles.booksSlider__cover}>
                  <a target="_blank`" href={litres_url} title={`Скачать книгу ${title}`}>
                    <img loading="lazy" src={`${DEV_ABSOLUTE_URL}/covers_185/${external_id}.jpg`} height="256" width="180" alt="slide" onError={displayNoneImgOnError} />
                  </a>
                </div>
                <div className={styles.booksSlider__name}>
                  <a target="_blank" href={litres_url}>
                    {book.title}
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default SlickSliderPartner;
