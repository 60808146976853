import { FC } from 'react';
import Link from 'next/link';
import { IPodcastPage } from '@/interfaces/podcastsPage.interface';
import { displayNoneImgOnError } from '@/helpers/helpers';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import { getAuthorFullName } from '@/helpers/helpers';
import styles from './PodcastsBlock.module.scss';

interface IPodcastsBlock {
  podcastsBlock: IPodcastPage;
}

const PodcastsBlock: FC<IPodcastsBlock> = ({ podcastsBlock }) => {
  return (
    <div className={styles.podcastsBlock}>
      {podcastsBlock.data.map((book, i) => {
        const podcastLink = `/podcasts/list/${book.alias}/`;
        const bookRating = book.rating;
        const greyStars = 5 - bookRating;

        return (
          <div key={book.id} className={i >= 4 ? `${styles.podcastsBlock__item} ${styles.podcastsBlock__item_mob}` : `${styles.podcastsBlock__item}`}>
            <div className={styles.podcastsBlock__imgWrap}>
              <Link prefetch={false} className={styles.podcastsBlock__imgLink} href={podcastLink}>
                <img className={styles.podcastsBlock__img} src={`${DEV_ABSOLUTE_URL}/covers/${book.external_id}.jpg`} width="110" height="140" alt={book.title} onError={displayNoneImgOnError} />
                <span className={styles.podcastsBlock__type}>Подкаст</span>
              </Link>
            </div>

            <Link prefetch={false} className={styles.podcastsBlock__name} href={podcastLink}>
              {book.title}
            </Link>
            <div className={styles.podcastsBlock__author}>{getAuthorFullName(book.authors[0].first_name, book.authors[0].middle_name, book.authors[0].last_name)}</div>

            <div className={styles.podcastsBlock__rating}>
              {Array.from(Array(bookRating), () => {
                return <span className={`${styles.podcastsBlock__star} ${styles.podcastsBlock__star_active}`} key={`${Math.random()}-orange`}></span>;
              })}
              {Array.from(Array(greyStars), () => {
                return <span className={`${styles.podcastsBlock__star}`} key={`${Math.random()}-gray`}></span>;
              })}
              {bookRating > 0 && <span className={styles.podcastsBlock__starRate}>{bookRating}</span>}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default PodcastsBlock;
