import { FC } from 'react';
import Layout from '@/components/layout/Layout';
import SectionTitle from '@/components/ui/section-title/SectionTitle';
import BookCardItem from '@/components/ui/book-card-item/BookCardItem';
import SlickSlider from '@/components/ui/slick-slider/SlickSlider';
import SlickSliderPartner from '@/components/ui/slick-slider/SlickSliderPartner';
import SectionTitleMenu from '@/components/ui/section-title-menu/SectionTitleMenu';
import PodcastsBlock from './podcasts-block/PodcastsBlock';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';
import { ISideBarData } from '@/interfaces/sidebar.interface';
import { IPodcastPage } from '@/interfaces/podcastsPage.interface';

interface IHomePage extends ISideBarData {
  popularBooks: IBooksPageData[];
  newBooks: IBookPage;
  advertisingBlock: IAdvertisingBlock;
  podcastsBlock: IPodcastPage;
}

const Home: FC<IHomePage> = ({ popularBooks, newBooks, advertisingBlock, sidebarGenres, podcastsBlock }) => {
  //seo block
  const SEO_TITLE = 'Куча книг - электронная библиотека. Скачать книгу, читать книги онлайн, электронные книги бесплатно.';
  const SEO_DESCRIPTION = 'Электронная библиотека - Куча книг. У нас можно читать книги, скачать книгу online бесплатно. Бесплатно - Электронная библиотека и электронные книги.';

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref="/" isDefaultPopularSlider={false} sidebarGenres={sidebarGenres}>
      {podcastsBlock && podcastsBlock.data.length > 0 && (
        <>
          <SectionTitle name={'Популярные подкасты'} />
          <PodcastsBlock podcastsBlock={podcastsBlock} />
        </>
      )}

      <SectionTitle name={'Новинки'} />
      <SlickSlider newBooks={newBooks.data} />

      <SectionTitleMenu extraClassName={'sectionTitleMenu_mainPage'}>
        <SectionTitle name={'Популярные книги'} extraClassName={'sectionHeading_plain'} />
      </SectionTitleMenu>
      <div data-cards="list" className="cardsWrapper">
        {popularBooks.length ? (
          popularBooks.map((book) => <BookCardItem createdAt={book.created_at} alias={book.alias} rating={book.rating} key={book.id} author={book.author} book={book.book} draft={book.draft} readOnline={book.read_online} />)
        ) : (
          <div>Книги не найдены</div>
        )}
      </div>
      <SectionTitle name={'Лучшие книги нашего партнера'} />
      <SlickSliderPartner books={advertisingBlock} />
    </Layout>
  );
};

export default Home;
