import { FC } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getAuthorFullName } from '@/helpers/helpers';
import { DEV_ABSOLUTE_URL, slickSliderConfig } from '@/helpers/variables';
import { INewBook } from '@/interfaces/home.interface';
import { displayNoneImgOnError } from '@/helpers/helpers';
import styles from './SlickSlider.module.scss';

interface ISlickSlider {
  newBooks: INewBook[];
}

const SlickSlider: FC<ISlickSlider> = ({ newBooks }) => {
  return (
    <div className={styles.booksSlider}>
      <div className={styles.booksSlider__wrap}>
        <Slider {...slickSliderConfig}>
          {newBooks!.map((book: INewBook) => {
            const { title, id } = book.book;
            const fullAuthorAlias = `/avtor/${book.author.alias}/`;
            const fullBookAlias = `/avtor/${book.author.alias}/${book.alias}/`;
            let authorName = '';

            if (book.author) {
              authorName = getAuthorFullName(book.author.first_name, book.author.middle_name, book.author.last_name);
            }

            return (
              <div key={id} className={styles.booksSlider__item}>
                <div className={styles.booksSlider__cover}>
                  <Link prefetch={false} href={fullBookAlias} title={`Скачать книгу ${title}`}>
                    <img src={`${DEV_ABSOLUTE_URL}/covers_185/${book.book.external_id}.jpg`} height="256" width="180" alt="slide" onError={displayNoneImgOnError} />
                  </Link>
                </div>
                <div className={styles.booksSlider__name}>
                  <Link prefetch={false} href={fullBookAlias}>
                    {title}
                  </Link>
                </div>
                <div className={styles.booksSlider__author}>
                  <Link prefetch={false} href={fullAuthorAlias}>
                    {authorName}
                  </Link>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default SlickSlider;
