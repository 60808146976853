import Home from '@/components/screens/home/Home';
import { NextPage, GetStaticProps } from 'next';
import { API_URL, POPULAR_BOOKS, REVALIDATE_TIME, SIDEBAR_API } from '@/helpers/variables';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';
import { IPodcastPage } from '@/interfaces/podcastsPage.interface';
import { ISideBarData } from '@/interfaces/sidebar.interface';

interface IHomePage extends ISideBarData {
  popularBooks: IBooksPageData[];
  newBooks: IBookPage;
  advertisingBlock: IAdvertisingBlock;
  podcastsBlock: IPodcastPage;
}

const HomePage: NextPage<IHomePage> = ({ popularBooks, newBooks, advertisingBlock, sidebarGenres, podcastsBlock }) => {
  return <Home popularBooks={popularBooks} newBooks={newBooks} advertisingBlock={advertisingBlock} podcastsBlock={podcastsBlock} sidebarGenres={sidebarGenres} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const [popularRes, newRes, advertisingBlockRes, podcastsBlockRes] = await Promise.all([
    fetch(`${API_URL}satellite/books/popularity?page=1&page_size=${POPULAR_BOOKS}`),
    fetch(`${API_URL}satellite/books/news?page=1&page_size=6`),
    fetch(`${API_URL}advertising_block`),
    fetch(`${API_URL}/satellite/podcast/podcast?page=1&page_size=10&sort_field=rating&sort_reversed=true`),
  ]);

  const [popularBooks, newBooks, advertisingBlock, podcastsBlock] = await Promise.all([popularRes.json(), newRes.json(), advertisingBlockRes.json(), podcastsBlockRes.json()]);

  //сайдбар
  const sidebarGenresRes = await fetch(`${SIDEBAR_API.genres}`);
  const sidebarGenres = await sidebarGenresRes.json();

  return { props: { popularBooks, newBooks, advertisingBlock, sidebarGenres, podcastsBlock }, revalidate: REVALIDATE_TIME };
};

export default HomePage;
